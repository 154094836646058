<template>
    <div class="page">
        <Header @Agreement="agreementChange" />
        <div class="page_body">
            <div class="titlt_bg" :class="titlt_bg[type]"></div>
            <div class="content">
                <!-- <img style="width: 100%" :src="agreement[type]" /> -->
                <div class="menu">
                    <div
                        class="menu_item"
                        v-for="(item,index) in menuList"
                        :key="index"
                        :class="type == item.type ? 'active' : ''"
                        @click="type = item.type"
                    >{{ item.title }}</div>
                </div>
                <!-- // 富文本 -->
                <div class="content_box" v-if="htmlType.some(el => el == type)">
                    <div v-html="htmlTxt[type]"></div>
                </div>
                <div class="content_box" v-else>
                    <img style="width: 100%" :src="agreement[type]" />
                </div>
            </div>
        </div>
        <Footer @Agreement="agreementChange" />
    </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import { scrollTo } from '@/utils/scroll-to.js';
import htmlJS from './html';
export default {
    name: 'HomewebIndex',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            type: 'about',
            htmlType: ['yinsi', 'fuwu'],
            htmlTxt: htmlJS,
            agreement: {
                yinsi: require('../../assets/agreement/yinsi.png'),
                fuwu: require('../../assets/agreement/fuwu.png'),
                about: require('../../assets/agreement/about.png'),
                contact: require('../../assets/agreement/contact.png'),
                join: require('../../assets/agreement/join.png'),
            },
            titlt_bg: {
                yinsi: 'titlt_bg_yinsi',
                fuwu: 'titlt_bg_fuwu',
                about: 'titlt_bg_about',
                contact: 'titlt_bg_contact',
                join: 'titlt_bg_join',
            },
            menuList: [
                {
                    title: '关于我们',
                    type: 'about',
                },
                {
                    title: '联系我们',
                    type: 'contact',
                },
                {
                    title: '加入我们',
                    type: 'join',
                },
                {
                    title: '隐私政策',
                    type: 'yinsi',
                },
                {
                    title: '服务协议',
                    type: 'fuwu',
                },
            ],
        };
    },
    created() {
        this.type = this.$route.query.type || 'about';
    },
    mounted() {
        scrollTo(0, 800);
    },
    methods: {
        agreementChange(type) {
            this.type = type;
            scrollTo(0, 800);
        },
    },
};
</script>

<style lang="scss" scoped>
// Layout
.page {
    width: 100%;
    background-color: #f4f4f4;

    position: relative;

    .titlt_bg {
        width: 100%;
        height: 450px;
        background-color: #666;
    }

    .titlt_bg_about {
        background: url('../../assets/background/about.png') no-repeat;
        background-size: 100% 100%;
    }

    .titlt_bg_contact {
        background: url('../../assets/background/contact.png') no-repeat;
        background-size: 100% 100%;
    }

    .titlt_bg_join {
        background: url('../../assets/background/join.png') no-repeat;
        background-size: 100% 100%;
    }

    .titlt_bg_yinsi {
        background: url('../../assets/background/yinsi.png') no-repeat;
        background-size: 100% 100%;
    }

    .titlt_bg_fuwu {
        background: url('../../assets/background/fuwu.png') no-repeat;
        background-size: 100% 100%;
    }

    .content {
        width: 1000px;
        min-height: 500px;
        margin: -80px auto 0 auto;
        background-color: #fff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        display: flex;
        justify-content: space-between;

        .menu {
            width: 200px;
            text-align: center;
            flex: 0 0 auto;
            margin-top: 50px;

            .menu_item {
                font-size: 16px;
                padding: 15px 0;
                cursor: pointer;
                margin-bottom: 20px;
            }

            .active {
                position: relative;
                &::after {
                    content: '';
                    background-color: #9a609c;
                    position: absolute;
                    width: 30px;
                    height: 3px;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .content_box {
            flex: 1 1 auto;
            padding: 60px 40px;
            box-sizing: border-box;
        }
    }
}
</style>